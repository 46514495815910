
<template>
  <div v-if="data">
    <CmsButton
      v-for="(button, index) in data.buttons"
      :key="index"
      class="d-inline"
      :value="button"
    />
  </div>
</template>

<script>
import Base from './Base.vue'
export default {
  extends: Base,
}
</script>

<style lang="scss">
</style>